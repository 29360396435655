import { RouterProvider, createBrowserRouter } from "react-router-dom";

import "./App.scss";

import Main from "./pages/main/Main";

const router = createBrowserRouter([{ path: "/", element: <Main /> }]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
